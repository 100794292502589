import React from 'react';
import { CtaSectionExtended } from 'modules/cta-section-extended';

export const ServicesCtaSectionExtended = () => {
    return (
        <CtaSectionExtended title="ctaExtendedTitle">
            <p>
                Bring your vision to life with{' '}
                <strong>cutting-edge solutions</strong> in web, mobile, and
                data-driven technologies designed to drive growth and
                efficiency.
            </p>
            <ul>
                <li>Free consultation for bespoke software solutions</li>
                <li>
                    Discover how custom development can transform your digital
                    presence and operations
                </li>
                <li>
                    Expertise in web design & development, mobile apps,
                    no-code/low-code solutions, data science, and machine
                    learning
                </li>
                <li>
                    Unlock the power of your data with advanced BI and analytics
                </li>
                <li>Streamline your workflow and enhance user experiences</li>
            </ul>
        </CtaSectionExtended>
    );
};
