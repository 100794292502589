import React from 'react';
import styled from 'styled-components';
import { ClutchSection } from 'views/index/clutch-section';
import { CONSTANTS } from 'constants/styles/constants';
import { OurServicesSection } from 'modules/our-services-section';

import { OUR_SERVICES_PART_1, OUR_SERVICES_PART_2 } from './constant';

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6.25rem;

    ${CONSTANTS.MEDIA.max1024`
      gap: 3rem;
    `}

    ${CONSTANTS.MEDIA.max500`
      gap: 2rem;
    `}
`;

export const ServicesSection = () => {
    return (
        <SWrapper>
            <OurServicesSection
                title="services.ourServices.header"
                paragraph="services.ourServices.subtitle"
                data={OUR_SERVICES_PART_1}
                margin={false}
            />
            <ClutchSection margin={false} />
            <OurServicesSection
                wrapperCustomStyles={{ marginTop: 0 }}
                data={OUR_SERVICES_PART_2}
            />
        </SWrapper>
    );
};
