import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ServicesMainSection } from 'views/services/services-main-section';
import { FaqSection } from 'views/single-blog-genre/faq-section';
import { Container } from 'components/container';
import { StyledLink } from 'components/styled-link';
import { PATHS } from 'constants/paths';
import { LatestArticles } from 'modules/latest-articles';
import { PerksWithImageSection } from 'modules/perks-with-image-section';
import { QuoteBannerSection } from 'modules/quote-banner-section';
import { RequestSection } from 'modules/request-section';
import { WorkingWithUsSection } from 'modules/working-with-us';
import { array } from 'prop-types';

import { PERKS, PERKS_LINKS } from './_constants';
import { ServicesSection } from './our-services-section';
import { ServicesCtaSectionExtended } from './services-cta-section-extended';
import { ServicesTrustedUsSection } from './trusted-us-section';

const SContainer = styled(Container)`
    padding-top: 10rem;
`;

const LINKS_CHUNKS = {
    FIRST: 'team of experts',
    SECONDS: 'we provide a solution',
};

const resolveTo = (chunk) => {
    if (chunk === LINKS_CHUNKS.FIRST) {
        return PATHS.TEAM;
    }

    return PATHS.SOFTWARE_DEVELOPMENT_PROCESS;
};

const Props = {
    blogPosts: array.isRequired,
    faqData: array.isRequired,
};

export const ServicesView = ({ blogPosts, faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <ServicesMainSection />
            <ServicesTrustedUsSection />
            <ServicesSection />
            <ServicesCtaSectionExtended />
            <PerksWithImageSection
                title="services.perks.title"
                alt="services.perks.alt"
                perks={PERKS}
                links={PERKS_LINKS}
            />
            <QuoteBannerSection
                quote="quotes.mobileRealityCompany"
                author="quotes.justynaSznajder"
                position="quotes.productManager"
            />
            <WorkingWithUsSection
                title={formatMessage({
                    id: 'services.customSoftwareDevelopment',
                })}
                alt="services.imgAlts.workingWithTeam"
                descriptions={[
                    formatMessage(
                        {
                            id: 'services.whenYouWorkWithUs',
                        },
                        {
                            link: (chunks) => (
                                <StyledLink to={resolveTo(chunks[0])}>
                                    {chunks}
                                </StyledLink>
                            ),
                        },
                    ),
                ]}
            />
            <RequestSection />
            <LatestArticles latestPostsList={blogPosts} fillBackground />
            <SContainer>
                <FaqSection
                    title={faqData.title}
                    data={faqData.data}
                    parseRawAnswer
                />
            </SContainer>
        </>
    );
};

ServicesView.propTypes = Props;
